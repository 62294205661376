<template>
    <div class="mb-10">
        <div class="first-section d-flex justify-space-between align-center py-7">
            <div class="d-flex">
                <img src="@/assets/logo/newblack.svg" alt="" width="120">
                <div class="delivery ml-8 d-none d-sm-block">
                    <div class="text text-uppercase body-3">
                        Deliver To:
                    </div>
                    <div class="icons d-flex mt-1">
                        <img src="@/assets/icons/flag/usa.svg" class="mr-1" alt="USA" width="25">
                        <img src="@/assets/icons/flag/canada.svg" class="mx-1" alt="USA" width="25">
                        <img src="@/assets/icons/flag/mexico.svg" class="mx-1" alt="USA" width="25">
                    </div>
                </div>
                <div class="contact align-center ml-16 d-none d-lg-flex">
                    <img src="@/assets/icons/contact/phone-blue.svg" alt="">
                    <div class=" ml-5">
<!--                        <div class="text-16">-->
<!--                            Phone number-->
<!--                        </div>-->
<!--                        <div class="text-20-60">-->
<!--                            +1 469 830 8777-->
<!--                        </div>-->
                    </div>
                </div>
                <div class="contact align-center ml-16 d-none d-lg-flex">
                    <img src="@/assets/icons/contact/email-blue.svg" alt="">
                    <div class=" ml-5">
                        <div class="text-16">
                            Email
                        </div>
                        <div class="text-20-60">
                            info@freightcrew.us
                        </div>
                    </div>
                </div>
            </div>
            <div class="d-none d-sm-flex d-lg-none">
                <a href="#" class="mr-3">
                    <v-hover v-slot="{ hover }">
                        <div class="">
                            <img v-show="!hover" src="@/assets/icons/iconsnew/fb.svg" alt="">
                            <img v-show="hover" src="@/assets/icons/iconsnew/fbh.svg" alt="">
                        </div>
                    </v-hover>
                </a>
                <a href="#" class="mr-3">
                    <v-hover v-slot="{ hover }">
                        <div class="">
                            <img v-show="!hover" src="@/assets/icons/iconsnew/insta.svg" alt="">
                            <img v-show="hover" src="@/assets/icons/iconsnew/instah.svg" alt="">
                        </div>
                    </v-hover>
                </a>
                <a href="#">
                    <v-hover v-slot="{ hover }">
                        <div class="">
                            <img v-show="!hover" src="@/assets/icons/iconsnew/twee.svg" alt="">
                            <img v-show="hover" src="@/assets/icons/iconsnew/tweeh.svg" alt="">
                        </div>
                    </v-hover>
                </a>
            </div>
            <div class="d-none d-sm-block">
                <v-btn x-large class="secondary px-15 py-8 text-14 text-transform-none" depressed :to="{name: 'frontend.calculator'}">Get quotes</v-btn>
            </div>
            <div class="d-block d-sm-none">
                <div class="contact align-center ml-sm-16 d-flex">
                    <div class=" mr-5">
<!--                        <div class="text-12 text-right">-->
<!--                            Phone number-->
<!--                        </div>-->
<!--                        <div class="text-14-60">-->
<!--                            +1 469 830 8777-->
<!--                        </div>-->
                    </div>
                    <img src="@/assets/icons/contact/phone-blue.svg" width="35" alt="">
                </div>
            </div>
        </div>
        <v-btn x-large class="d-flex d-sm-none secondary  text-14 text-transform-none" depressed :to="{name: 'frontend.calculator'}">Get quotes <v-icon>mdi-chevron-right</v-icon></v-btn>
        <v-divider class="d-none d-sm-block"></v-divider>
        <div class="">
            <v-row>
                <v-col cols="12" sm="8" lg="9">
                    <v-row>
                        <v-col cols="12" sm="6" lg="4" class="text-center text-sm-left">
                            <p class="text-18-60 mb-4">Services</p>
                            <p class="text-16 mb-2"><router-link :to="{ name: 'frontend.services.expedited', params: {} }" class="dark-text text-decoration-none">Expedited shipping</router-link></p>
                            <p class="text-16 mb-2"><router-link :to="{ name: 'frontend.services.ltl', params: {} }" class="dark-text text-decoration-none">LTL Shipping</router-link></p>
                            <p class="text-16 mb-2"><router-link :to="{ name: 'frontend.services.international', params: {} }" class="dark-text text-decoration-none">International shipping</router-link></p>
                            <p class="text-16"><router-link :to="{ name: 'frontend.services.airfreight', params: {} }" class="dark-text text-decoration-none">Airfreight</router-link></p>
                            <div class="d-block d-sm-none">
                                <p class="text-18-60 mb-4"><router-link :to="{ name: 'frontend.agent', params: {} }" class="dark-text text-decoration-none">Agents</router-link></p>
                                <p class="text-18-60 mb-4"><router-link :to="{ name: 'frontend.about', params: {} }" class="dark-text text-decoration-none">About us</router-link></p>
                                <p class="text-18-60 mb-4">Blog</p>
                                <p class="text-18-60"><router-link :to="{ name: 'frontend.contact', params: {} }" class="dark-text text-decoration-none">Contact us</router-link></p>
                            </div>
                        </v-col>
                        <v-col cols="12" sm="6" lg="4" class="d-none d-sm-block">
                            <p class="text-18-60 mb-4"><router-link :to="{ name: 'frontend.careers', params: {} }" class="dark-text text-decoration-none">Careers</router-link></p>
                            <p class="text-16 mb-2">Freight agent</p>
                            <p class="text-16 mb-2">Sales Manager</p>
                            <p class="text-16 mb-2">Freight agent</p>
                            <p class="text-16">Sales Manager</p>
                        </v-col>
                        <v-col class="d-none d-lg-block" cols="12" lg="4">
                            <p class="text-18-60 mb-4"><router-link :to="{ name: 'frontend.agent', params: {} }" class="dark-text text-decoration-none">Agents</router-link></p>
                            <p class="text-18-60 mb-4"><router-link :to="{ name: 'frontend.about', params: {} }" class="dark-text text-decoration-none">About us</router-link></p>
                            <p class="text-18-60 mb-4">Blog</p>
                            <p class="text-18-60 mb-4"><router-link :to="{ name: 'frontend.contact', params: {} }" class="dark-text text-decoration-none">Contact us</router-link></p>
                        </v-col>
                    </v-row>
                </v-col>
                <v-col class="d-none d-sm-block d-lg-none text-right text-center text-sm-right" cols="12" sm="4">
                    <p class="text-18-60 mb-4"><router-link :to="{ name: 'frontend.agent', params: {} }" class="dark-text text-decoration-none">Agents</router-link></p>
                    <p class="text-18-60 mb-4"><router-link :to="{ name: 'frontend.about', params: {} }" class="dark-text text-decoration-none">About us</router-link></p>
                    <p class="text-18-60 mb-4">Blog</p>
                    <p class="text-18-60"><router-link :to="{ name: 'frontend.contact', params: {} }" class="dark-text text-decoration-none">Contact us</router-link></p>
                </v-col>
                <v-col cols="12" lg="3">
                    <div class="d-none d-lg-flex justify-lg-end">
                        <a href="#" class="mr-3">
                            <v-hover v-slot="{ hover }">
                                <div class="">
                                    <img v-show="!hover" src="@/assets/icons/iconsnew/fb.svg" alt="">
                                    <img v-show="hover" src="@/assets/icons/iconsnew/fbh.svg" alt="">
                                </div>
                            </v-hover>
                        </a>
                        <a href="#" class="mr-3">
                            <v-hover v-slot="{ hover }">
                                <div class="">
                                    <img v-show="!hover" src="@/assets/icons/iconsnew/insta.svg" alt="">
                                    <img v-show="hover" src="@/assets/icons/iconsnew/instah.svg" alt="">
                                </div>
                            </v-hover>
                        </a>
                        <a href="#">
                            <v-hover v-slot="{ hover }">
                                <div class="">
                                    <img v-show="!hover" src="@/assets/icons/iconsnew/twee.svg" alt="">
                                    <img v-show="hover" src="@/assets/icons/iconsnew/tweeh.svg" alt="">
                                </div>
                            </v-hover>
                        </a>
                    </div>
                </v-col>
            </v-row>
            <div class="d-flex flex-column flex-md-row justify-space-between align-center align-md-end pb-8">
                <div class="order-2 order-md-1 text-center text-sm-left">
                    ©{{new Date().getFullYear()}} Freight Crew Inc. | All rights reserved
                </div>
                <div class="d-flex align-lg-end flex-column flex-column h-100 align-center justify-sm-space-between justify-lg-end order-1 order-md-2">
                    <p class="mb-0"><router-link :to="{ name: 'frontend.terms', params: {} }" class="dark--text">Terms and conditions</router-link></p>
                    <p class="mb-0">Created by <a href="https://gbmf.tech"><img src="@/assets/logo/gbmf-blue.svg" height="12px" alt=""></a></p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'FooterBlock'
}
</script>
